<template>
  
    <div class="">
        <div class="">
            <div class="row justify-content-center mobileH">
                <div>
                    <div class="testimonial-about-slider-active" style="position :relative">
                        <span @click.prevent="showPrev" class="prev slick-arrow"><i class="fal fa-arrow-left"></i></span>
                        <VueSlickCarousel v-bind="settingsForArticle" ref="articleCarosel">
                            <div class="testimonial-parent-item">
                                <img src="@/assets/images/foto-1.png" class="carouselImg">
                            </div>
                            <div class="testimonial-parent-item">
                                <img src="@/assets/images/foto-2.png" class="carouselImg">
                            </div>
                            <div class="testimonial-parent-item">
                                <img src="@/assets/images/foto-3.png" class="carouselImg">
                            </div>
                            <div class="testimonial-parent-item">
                                <img src="@/assets/images/foto-4.png" class="carouselImg">
                            </div>
                        </VueSlickCarousel>
                        <span @click.prevent="showNext" class="next slick-arrow" style="display: block;"><i class="fal fa-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="imgFooter" style="justify-content: center; text-align: center; padding-top: 20px !important; padding-bottom: 20px !important;">
                <span class="mobileFooter" style="color: white;"><b>HUNGER GEMS FESTIVAL</b> <br> in collaboration with</span>
                <span class="webFooter" style="color: white;"><b>HUNGER GEMS FESTIVAL</b> in collaboration with</span>
                <br class="mobileFooter">
                <img src="@/assets/images/partner-gofood.png" width="50" style="margin-right: 20px;" class="webMargin partnerLogo">
                <img src="@/assets/images/partner-IG.png" width="30" style="margin-right: 20px;" class="webMargin partnerLogoIG">
                <img src="@/assets/images/partner-Blu.png" width="50" class="webMargin partnerLogo">
            </div>
        </div>
    </div>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
        components:{ VueSlickCarousel},
        data(){ 
            return{
                settingsForArticle: {
                    autoplay: false,
                    arrows:false,
                    dots:false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
                settingsForPeople:{
                    centerMode: true,
                    centerPadding: '0px',
                    autoplay: false,
                    arrows:false,
                    dots:false,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            }
        },
        methods:{ 
            showNext() {
                this.$refs.articleCarosel.next()
                this.$refs.peopleCarosel.next()
            },
            showPrev(){
                this.$refs.articleCarosel.prev()
                this.$refs.peopleCarosel.prev()
            },
        }


}
</script>

<style>
.testimonial-about-slider-active .testimonial-box{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.testimonial-about-slider-active .testimonial-parent-item {
    height: 733px;
}
.slick-slide{
    padding: 0;
}
.slick-arrow.next{
    right: 30px !important;
} 
.slick-arrow.prev {
    left: 30px !important;
}
.slick-arrow {
    top: 50% !important;
}
@media screen and (orientation:landscape) {
    .imgFooter {
        margin-top: -75px;
        background-color: black;
        background-size: 100% 20%;
        z-index: 1;
        position: relative;
    }
    .mobileFooter {
        display: none;
    }
    .webMargin {
        margin-left: 20px !important;
    }
}
@media screen and (orientation:portrait) {
    .partnerLogoIG {
        width: 7% !important;
        margin-top: 8px !important;
    }
    .partnerLogo {
        width: 10% !important;
        margin-top: 8px !important;
    }
    .webFooter {
        display: none;
    }
    .imgFooter {
    margin-top: -93px;
    background-color: black;
    background-size: 100% 20%;
    z-index: 1;
    position: relative;
}
    .mobileH {
        height: 39vh !important;
    }
    .carouselImg {
        height: 28vh !important;
        width: 100vw !important;
    }
    .slick-arrow {
        top: 20% !important;
    }
}
</style>