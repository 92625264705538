<template>
    <home-six/>

</template>

<script>
import HomeSix from '../components/HomeSix/index'
export default {
	components: {HomeSix  },

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');
body {
    font-family: 'Montserrat' !important;
}

h2, h4 {
    font-family: 'Hanson_Bold' !important;
}

h1, h3 {
    font-family: 'Hanson Bold' !important;
}

</style>