<template>
      <!--====== APPIE HERO PART START ======-->
    
    <section class="" :style="{'background-image': 'url(' + require('../../assets/images/BG.png') + ')','height': '100vh', 'justify-content': 'center'}">
        <div class="">
            <div class="row align-items-center newbg">
                <div class="">
                    <div class="appie-hero-content appie-hero-content-6">
                        <img class="logo mobileFooter" src="@/assets/images/hglogo.png" alt=""/>
                        <h1 class="appie-title newfont" style="color: black;">DISCOVER<br>THE CURATED <br> CULINARY GEMS</h1>
                        <div class="mobilespace"><br></div>
                        <a href="#carousel"><img class="center" src="@/assets/images/downButton.png" alt=""></a>
                    </div>
                </div>
                <div class="col-lg">
                    <div class="appie-hero-thumb-6">
                        <div class="thumb wow animated fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                            <!-- <img width="500" class="center" src="@/assets/images/hglogocollab.png" alt=""> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE HERO PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>
@media screen and (orientation:portrait) {
    .newbg {
        /* background-image: url("/assets/images/homepage.png"); */
        background-size: 170% 90%;
        background-repeat: no-repeat;
        background-position: center;
        height: 90vh;
        padding-top: 100px;
    }
    .squarebg {
        -moz-box-shadow: 0 5px 5px #ccc;
        -webkit-box-shadow: 0 5px 5px #ccc;
        box-shadow: 0 5px 5px #ccc;
    }
    .festival {
        margin-left: auto;
        margin-right: auto;
        width: 40% !important;
        justify-content: center;
}
    .center {
        margin-top: 10vh !important;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
}
    .newfont {
        font-family: 'Hanson_Bold' !important;
        font-size: 30px !important;
        margin-top: 70px !important;
        margin-bottom: 0px !important;
    }
    .mobilespace {
        visibility: hidden;
        display: none;
    }
    .row {
        --bs-gutter-x: 0rem !important;
        margin-right: none !important;
    }
}
@media screen and (orientation:landscape) {
    .newbg {
        /* background-position: center; */
        background-size: 100%;
        /* background-repeat: no-repeat; */
        height: 100vh;
    }
    .margin-left {
        margin-left: 30px;
        padding-top: 10px !important;
    }
    .squarebg {
        -moz-box-shadow: 0 5px 5px #ccc;
        -webkit-box-shadow: 0 5px 5px #ccc;
        box-shadow: 0 5px 5px #ccc;
    }
    .festival {
        margin-left: auto;
        margin-right: auto;
        width: 0% !important;
        justify-content: center;
}
    .center {
        position: absolute;
        bottom: 50px;
        margin-left: 49%;
        width: 3%;
}
.newfont {
    font-family: 'Hanson_Bold' !important;
    margin-left: 30px;
    margin-top: 120px !important;
    font-size: 90px !important;
    line-height: 100px !important;
    text-align: center;
    margin-bottom: 50px !important;
}
.mobilespace {
    display: block;
}
}

</style>