<template>
  
      <!--====== APPIE PROJECT PART START ======-->
    
    <section class="appie-project-area newbgtenant pb-80" style="background-color: black; height: 100vh; justify-content: center; display:flex; align-items: center;">
        <div class="">
            <div class="">
                <img src="@/assets/images/Text.png" class="footerImg" style="margin-bottom: 50px;">
                 <a href="https://docs.google.com/forms/d/e/1FAIpQLSfxB4yTg80kJAFlxhgZfadghoq3eVoz5U9O7bfM6ssJANQXFw/viewform"><img class="buttonView" src="@/assets/images/button-in.png"></a>
            </div>
        </div>
    </section>
    
    <!--====== APPIE PROJECT PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        },
        title1: String
    }

}
</script>

<style>
.newbgtenant {
    background-size: 200% 120%;
    background-repeat: no-repeat;
}
.btn {
    justify-content: center;
    background-color: white;
    display: inline-block;
}

@media screen and (orientation:portrait) {
    .newfonthigh {
    font-family: 'Yeseva one' !important;
    font-size: 30px !important;
}
    .mobilemargin {
        margin-left: 20px;
        margin-right: 20px;
    }
    .buttonView {
        margin-left: 38vw !important;
        width: 30%;
    }
    .footerImg {
        width: 80% !important;
        position: relative;
        left: 10vw !important;
    }
}
@media screen and (orientation:landscape) {
    .newfonthigh {
    font-family: 'Yeseva one' !important;
    font-size: 50px !important;
}
    .buttonView {
        margin-top: 100px !important;
        margin-left: 45vw !important;
        width: 15% !important;
    }
    .footerImg {
        margin-top: 200px !important;
        width: 70% !important;
        position: relative;
        left: 15vw !important;
    }
}

</style>