<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area">
    <img src="@/assets/images/line.png" style="padding-left: 20px; padding-right: 20px;">
    <div class="">
      <div class="row">
        <div class="justify-content-center" style="display: flex; color: white;">
          <div class="footer-widget-info">
            <a href="mailto:experience@hungergems.com"><i style="margin-right: 8px; color: white;" class="fal fa-envelope"></i><span style="color: white;" class="contacttext">experience@hungergems.com</span></a>
            <a href="https://instagram.com/hunger.gems?igshid=MTg0ZDhmNDA="><i class="fab fa-instagram" style="margin-right:8px; color: white;"></i><span style="color: white;" class="contacttext">hunger.gems</span></a>
            <br class="mobileFooter">
              <a>Copyright © 2022 Hunger Gems.<br class="mobileFooter"> All rights reserved.</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style>
@media screen and (orientation:landscape) {
  .contacttext {
    color: black;
    margin-right: 50px !important;
  }
}
@media screen and (orientation:portrait) {
  .contacttext {
    color: black;
  }
}

.appie-footer-area{
  background-color: black !important;
}
</style>