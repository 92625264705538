var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"off_canvars_overlay",class:[_vm.sidebar ? 'active' : ''],on:{"click":function($event){$event.preventDefault();return _vm.hideSidebar.apply(null, arguments)}}}),_c('div',{staticClass:"offcanvas_menu"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"offcanvas_menu_wrapper",class:[_vm.sidebar ? 'active' : ''],staticStyle:{"overflow-y":"scroll","overflow-x":"hidden"}},[_c('div',{staticClass:"canvas_close"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.hideSidebar}},[_c('i',{staticClass:"fa fa-times"})])]),_vm._m(0),_c('div',{staticClass:"text-left",style:(_vm.heightPro),attrs:{"id":"menu"}},[_c('ul',{staticClass:"offcanvas_main_menu"},_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:index,staticClass:"menu-item-has-children active",class:[_vm.menuOpen === item.name ? 'menu-open' : '']},[(
                      item.childrens && item.childrens.length > 0 && _vm.nasted
                    )?_c('span',{staticClass:"menu-expand"},[_c('i',{staticClass:"fa fa-angle-down"})]):_vm._e(),_c('a',{attrs:{"href":item.section},on:{"click":_vm.hideSidebar}},[_vm._v(" "+_vm._s(item.name))]),(item.childrens && _vm.nasted)?_c('ul',{staticClass:"sidebar-sub-menu",class:[
                      _vm.menuOpen === item.name ? 'expend_menu_item' : '',
                    ],attrs:{"id":item.name}},_vm._l((item.childrens),function(subItem,i){return _c('li',{key:i},[_c('router-link',{attrs:{"to":subItem.path ? subItem.path : '#'}},[_vm._v(_vm._s(subItem.name))])],1)}),0):_vm._e()])}),0)]),_vm._m(1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offcanvas-brand text-center mb-40"},[_c('img',{attrs:{"width":"100","src":require("@/assets/images/hglogo.png"),"alt":"appie"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-widget-info"},[_c('a',{staticClass:"fal fa-envelope",attrs:{"href":"#contact"}},[_vm._v(" experience@hungergems.id")]),_c('br'),_c('a',{staticClass:"fab fa-instagram",attrs:{"href":"https://instagram.com/hunger.gems?igshid=MTg0ZDhmNDA="}},[_vm._v(" hunger.gems")])])
}]

export { render, staticRenderFns }