<template>
    <ul>
        <li v-for="(item, index) in menuItems" :key="index">
            <!-- <router-link style="text-transform: capitalize;" v-if="item.path" to="#" >{{item.name}}</router-link> -->
            <a :href="item.section" style="text-transform: capitalize; font-weight: 550;">{{item.name}}</a>
            <ul v-if="item.childrens && nasted" class="sub-menu">
                <li v-for="(subItem, index) in item.childrens" :key="index">
                    <router-link style="text-transform: capitalize;" :to="subItem.path?subItem.path:'#'">{{subItem.name}}</router-link>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    props:{
        menuItems: {
            type:Array,
            required:true,
        },
         nasted:{
            type:Boolean,
            default:true
        },
        section: String
    },
    components: {
        
    }

}
</script>

<style>

</style>